import HomeIcon from "components/Icons/HomeIcon";
import AssetsIcon from "components/Icons/AssetsIcon";
import DocumentsIcon from "components/Icons/DocumentsIcon";
import RussianFlag from "components/Icons/Flags/ru";
import BritishFlag from "components/Icons/Flags/en";
import SwapIcon from "components/Icons/SwapIcon";

import { ELanguages } from "../i18n/constants";
import AlertIcon from "../components/Icons/AlertIcon";

import {
  ETransactionsOperationsTypes,
  ETransactionsTypesTranslations,
  TLinks,
  ETransactionsOperationsGroups
} from "./types";
import { paths } from "./paths";

export const lgMediaWidth = 992;

export const mobileMediaWidth375 = 375;
export const mobileMediaWidth400 = 400;
export const mobileMediaWidth460 = 460;
export const mobileMediaWidth = 575.98;
export const maxMobileMediaWidth = 767.98;
export const minTableMediaWidth = 768;
export const moreMdMedia = 868;
export const tableMediaWidth = 924;
export const maxTableMediaWidth = 1024;
export const minLaptopMediaWidth = 1199;
export const minShowSidebarWidth = 1336;
export const contentWidth = 996;

export const TAX_REPORT_2021 = "tax_report_2021";
export const YOOKASSA = "yookassa";
export const DEFAULT_FORMAT = "DD.MM.YYYY";

export const transactionOperation = [
  ETransactionsOperationsTypes.tradeSpot,
  ETransactionsOperationsTypes.tradeOtc,
  ETransactionsOperationsTypes.cryptoTransfer,
  ETransactionsOperationsTypes.cryptoOutcome,
  ETransactionsOperationsTypes.cryptoIncome,
  ETransactionsOperationsTypes.p2pSale,
  ETransactionsOperationsTypes.p2pPurchase,
  ETransactionsOperationsTypes.fiatOutcome,
  ETransactionsOperationsTypes.fiatIncome,
  ETransactionsOperationsGroups.groupCryptoSale,
  ETransactionsOperationsGroups.groupCryptoPurchase,
  ETransactionsOperationsTypes.tradePositionOpening,
  ETransactionsOperationsTypes.tradePositionClosing,
  ETransactionsOperationsTypes.innerTransfer,
  ETransactionsOperationsTypes.blockchainSwap,
  ETransactionsOperationsTypes.blockchainDex,
];

export const transactionOperationTypes = [
  { value: "", label: ETransactionsTypesTranslations.all },
  { value: ETransactionsOperationsTypes.tradeSpot, label: ETransactionsTypesTranslations.tradeSpot },
  { value: ETransactionsOperationsTypes.tradeOtc, label: ETransactionsTypesTranslations.tradeOtc },
  { value: ETransactionsOperationsTypes.cryptoTransfer, label: ETransactionsTypesTranslations.cryptoTransfer },
  { value: ETransactionsOperationsTypes.cryptoOutcome, label: ETransactionsTypesTranslations.cryptoOutcome },
  { value: ETransactionsOperationsTypes.cryptoIncome, label: ETransactionsTypesTranslations.cryptoIncome },
  { value: ETransactionsOperationsTypes.p2pSale, label: ETransactionsTypesTranslations.p2pSale },
  { value: ETransactionsOperationsTypes.p2pPurchase, label: ETransactionsTypesTranslations.p2pPurchase },
  { value: ETransactionsOperationsTypes.fiatOutcome, label: ETransactionsTypesTranslations.fiatOutcome },
  { value: ETransactionsOperationsTypes.fiatIncome, label: ETransactionsTypesTranslations.fiatIncome },
  { value: ETransactionsOperationsGroups.groupCryptoSale, label: ETransactionsTypesTranslations.cryptoSale },
  { value: ETransactionsOperationsGroups.groupCryptoPurchase, label: ETransactionsTypesTranslations.cryptoPurchase },
  { value: ETransactionsOperationsTypes.tradePositionOpening,
    label: ETransactionsTypesTranslations.tradePositionOpening },
  { value: ETransactionsOperationsTypes.tradePositionClosing,
    label: ETransactionsTypesTranslations.tradePositionClosing },
  { value: ETransactionsOperationsTypes.innerTransfer, label: ETransactionsTypesTranslations.innerTransfer },
  { value: ETransactionsOperationsTypes.blockchainSwap, label: ETransactionsTypesTranslations.blockchainSwap },
  { value: ETransactionsOperationsTypes.blockchainDex, label: ETransactionsTypesTranslations.blockchainDex },
];


export enum LinkIds {
  OVERVIEW = "overview",
  ASSETS = "assets",
  TRANSACTIONS = "transactions",
  AML = "aml",
  DOCUMENTS = "documents",
  TRADING_INSIGHT = "trading_insight",
  KYT = "kyt",
}

export const NAVIGATIONS = {
  [paths.HOME]: {
    id: LinkIds.OVERVIEW,
    to: paths.HOME,
    icon: HomeIcon,
    title: "naming.dashboard",
    active: true
  },
  [paths.ASSETS]: {
    id: LinkIds.ASSETS,
    to: paths.ASSETS,
    icon: AssetsIcon,
    title: "naming.exchangesAndWallets",
    active: true
  },
  [paths.TRANSACTIONS]: {
    id: LinkIds.TRANSACTIONS,
    to: paths.TRANSACTIONS,
    icon: SwapIcon,
    title: "naming.operations",
    active: true
  },
  [paths.AML]: {
    id: LinkIds.AML,
    to: paths.AML,
    icon: AlertIcon,
    title: "naming.amlRisks",
    active: process.env.REACT_APP_AML_DISABLED !== "true"
  },
  [paths.DOCUMENTS]: {
    id: LinkIds.DOCUMENTS,
    to: paths.DOCUMENTS,
    icon: DocumentsIcon,
    title: "naming.documents",
    active: process.env.REACT_APP_DOCUMENTS_DISABLED !== "true"
  },
  [paths.TRADING_INSIGHT]: {
    id: LinkIds.TRADING_INSIGHT,
    to: paths.TRADING_INSIGHT,
    icon: DocumentsIcon,
    title: "naming.tradingInsights",
    active: true
  },
};

export const NAVIGATION_LINKS: TLinks[] = [
  NAVIGATIONS[paths.HOME],
  NAVIGATIONS[paths.ASSETS],
  NAVIGATIONS[paths.TRANSACTIONS],
  NAVIGATIONS[paths.AML],
  NAVIGATIONS[paths.DOCUMENTS],
  NAVIGATIONS[paths.TRADING_INSIGHT],
  // NAVIGATIONS[paths.PROFILE],
];

export const colors = {
  main: "#748ADA",
  brown1: "#edd4c4",
  brown2: "#e1b79d",
  brown3: "#d59b76",
  brown4: "#c97e4f",
  brown5: "#b06536",
  brown6: "#894e2a",
  brown7: "#62381e",
  brown8: "#3b2212",
  brown9: "#21130a",

  grayMain: "#39393C",
  gray1: "#ffffff",
  gray2: "#f3f4f9",
  gray3: "#eeeff3",
  gray4: "#e8e9f0",
  gray5: "#d7d8e0",
  gray6: "#b9bbc2",
  gray7: "#8c8d94",
  gray8: "#626368",
  gray9: "#232325",

  grayLight: "#fafafc",
  blue2: "#99ACF2",
  blue3: "#475DAF",
  error2: "#E5424C",
  green1: "#219653",
  green3: "#59bd83",
  red1: "#FFF1F0",
  red2: "#FFCCC7",
  red3: "#FFA39E",
  red4: "#ff7875",
  red5: "#ff4d4f",
  red6: "#e5424C",
  complementary: "#FF6D88",
  complementary2: "#9E83F8",
  darkBlue: "#00d5d7",
  lightBlue: "#00e7e6",
  lettuce: "#CDF964",
  pink: "#D64686",
};

export type LanguageOption = { nativeName: string, icon: () => JSX.Element }
export const LANGUAGE_OPTIONS: Record<ELanguages, LanguageOption> = {
  [ELanguages.ruRU]: {
    nativeName: 'Русский',
    icon: RussianFlag
  },
  [ELanguages.enUS]: {
    nativeName: 'English',
    icon: BritishFlag
  },
};
