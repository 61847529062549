import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { getAMLHistoryId, getAMLSingleId, paths } from "config/paths";

import { useLayoutAppNameContext } from "components/Layout/hooks";

import { EAmlSingleIds } from "store/aml/types";
import { getAMLSingleAssetsSelector } from "store/assets/selectors";
import { amlSingleChecksSelector } from "store/aml/selectors";
import { formAmlSingleChecksId } from "store/aml/utils";
import {
  getReportSingleSelector,
  getSingleTaxReportDataSelector, taxReportResultStateSelector
} from "store/reports/selectors";
import { EReportStatus } from "store/reports/types";
import { amlChecksDetailsSelector } from "store/amlChecks/selectors";
import { EAmlCheckType } from "store/amlChecks/types";

import { getPageTitle } from "utils/history";

import MobileHeaderAssetSingleTitle from "../MobileHeaderAssetSingleTitle";

const MobileHeaderTitle: FC = () => {
  const { t, i18n } = useTranslation();
  const { routePath } = useLayoutAppNameContext();
  const amlSingleId = getAMLSingleId();
  const amlHistoryId = getAMLHistoryId();
  const isArchived = amlSingleId === EAmlSingleIds.archived;
  const isAml = amlSingleId === EAmlSingleIds.aml;
  const amlAsset = useSelector(getAMLSingleAssetsSelector(amlSingleId));
  const { data: amlCheckDetails, initialLoading: amlCheckDetailsInitialLoading }
    = useSelector(amlChecksDetailsSelector);

  const amlSingleChecksState = useSelector(amlSingleChecksSelector(formAmlSingleChecksId({
    account: amlSingleId,
    archived: isArchived,
    aml: isAml,
  })));
  const amlChecks = get(amlSingleChecksState, ["data"]);
  const { data: report, fetching, initialLoading } = useSelector(getReportSingleSelector);
  const { id } = useParams<{ id: string }>();
  const SingleTaxReport = useSelector(getSingleTaxReportDataSelector(id));
  const showDate = !(fetching || initialLoading) && report?.status === EReportStatus.formed;
  const { fetching: loadingReport } = useSelector(taxReportResultStateSelector);

  const formPageTitle = () => {
    switch (routePath) {
    case paths.SINGLE_ASSET:
      return <MobileHeaderAssetSingleTitle/>;
    case paths.TAX_REPORT_FORMED:
      const showDateDocument = !loadingReport && report?.status === EReportStatus.formed;

      if (!showDateDocument) {
        return <span>{t("naming.taxForm")}</span>;
      }

      const reportCreatedAt = dayjs(SingleTaxReport?.updated_at).locale(i18n.language).format("DD.MM.YYYY");
      return <>
        <span>{t("naming.taxForm")}</span>
        <div className="header-mobile__sub-title">{t("naming.formationDate")} {reportCreatedAt}</div>
      </>;
    case paths.DOCUMENTS_TRANSACTION_EXPORT_FORMED:

      if (!showDate) {
        return <span>{t("documentCreateModalForm.transactionsExportTitle")}</span>;
      }

      const transactionExportCreatedAt = dayjs(report?.formed_at).locale(i18n.language).format("DD.MM.YYYY");
      return <>
        <span>{t("documentCreateModalForm.transactionsExportTitle")}</span>
        <div className="header-mobile__sub-title">{t("naming.formationDate")} {transactionExportCreatedAt}</div>
      </>;
    case paths.DOCUMENTS_SOURCES_EXPORT_FORMED:
      if (!showDate) {
        return t("documentCreateModalForm.sourcesExportTitle");
      }

      const sourcesExportCreatedAt = dayjs(report?.formed_at).locale(i18n.language).format("DD.MM.YYYY");
      return <>
        <span>{t("documentCreateModalForm.sourcesExportTitle")}</span>
        <div className="header-mobile__sub-title">{t("naming.formationDate")} {sourcesExportCreatedAt}</div>
      </>;
    case paths.SINGLE_AML:
      if (isAml) {
        return <span>{t("amlPage.amlChecks")}</span>;
      }
      if (isArchived) {
        return <span>{t("amlPage.archived")}</span>;
      }
      if(amlAsset) {
        if (!amlAsset.parent) {
          return <span>{amlAsset.name}</span>;
        }
        return <>
          <span>{amlAsset.name}</span>
          <div className="header-mobile__sub-title">{amlAsset.parent}</div>
        </>;
      }
      break;
    case paths.AML_CHEKS_SINGLE:
      return <>
        <span>{amlCheckDetailsInitialLoading ? "" : amlCheckDetails?.type === EAmlCheckType.deposit
          ? t("amlChecks.transferCheck") : t("amlChecks.addressCheck")}</span>
        <div className="header-mobile__sub-title">
          {amlCheckDetailsInitialLoading ? "" : amlCheckDetails?.id}
        </div>
      </>;
    case paths.SINGLE_AML_HISTORY:
      const amlCheck = amlChecks?.results.find($check => $check?.id === amlHistoryId);
      if(amlCheck) {
        if (!amlCheck.checked_at) {
          return <span>{dayjs(amlCheck.checked_at).format("DD.MM.YYYY")}</span>;
        }
        return <>
          <span>{dayjs(amlCheck.checked_at).format("DD.MM.YYYY")}</span>
          <div className="header-mobile__sub-title">{dayjs(amlCheck.checked_at).format("HH:mm:ss")}</div>
        </>;
      }
      break;
    }

    return <span>{getPageTitle(routePath || "")}</span>;
  };

  return (
    <div className="header-mobile__title">
      {formPageTitle()}
    </div>
  );
};

export default MobileHeaderTitle;
