import { FC, Fragment, useEffect } from "react";
import cn from "classnames";
import { matchPath, useLocation } from "react-router-dom";
import { Layout as AntLayout } from 'antd';
import MediaQuery, { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { paths } from "config/paths";
import { maxMobileMediaWidth, minShowSidebarWidth } from "config/constants";

import Sidebar from "components/segments/Sidebar";
import UpgradePlan from "components/UpgradePlan";
import NewHeader from "components/segments/NewHeader";
import MobileTabMenu from "components/segments/MobileTabMenu";
import MobileHeader from "components/segments/MobileHeader";
import AssetsModal from "components/AssetsPageWrapper/components/AssetsModal";
import PricingActivateModal from "components/Pricing/components/PricingActivateModal";
import AMLActivateModal from "components/AMLActivate/components/AMLActivateModal";

import { reportsTypesRequest } from "store/reports/reducers";

import { isHere } from "utils/url";

import DashboardMobileOnboarding
  from "../../../DashboardPage/components/DashboardMobileOnboardingWrapper/component/DashboardMobileOnboarding";

import { ILayout } from "../../types";
import { useLayoutAppNameContext } from "../../hooks";

const PrivateContainer: FC<ILayout> = ({ children }) => {
  const { i18n } = useTranslation();
  const isMaxMobile = useMediaQuery({ query: `(max-width: ${maxMobileMediaWidth}px)` });
  const { routePath } = useLayoutAppNameContext();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const lightGreyMobile = matchPath(location.pathname, paths.HOME)?.isExact
    || location.pathname.includes(paths.PRICING)
    || location.pathname.includes(paths.AML);

  useEffect(() => {
    dispatch(reportsTypesRequest({}));
  }, [dispatch, i18n.language]);

  const isAvailableShowSidebar = useMediaQuery({ query: `(min-width: ${minShowSidebarWidth}px)` });

  const showMobileHeader = (): boolean => {
    if (paths.AML_CHEKS === pathname) {
      return false;
    }
    return isMaxMobile;
  };

  const showSidebar = () => ![paths.AML_CHEKS_SINGLE].includes(routePath as paths);

  return <Fragment>
    { showSidebar() && <MediaQuery minWidth={minShowSidebarWidth}>
      <Sidebar/>
    </MediaQuery>}
    <AntLayout className={cn("main-layout", {
      "sidebarExists": isAvailableShowSidebar && showSidebar()
    })}>
      {!isMaxMobile && <NewHeader/>}
      {showMobileHeader() && <MobileHeader/>}
      <main
        className={cn("main", {
          "p-t-0": routePath && [
            paths.TAX_REPORT_CREATE,
            paths.TAX_REPORT,
            paths.DOCUMENTS_CREATE_TRANSACTION_EXPORT,
            paths.DOCUMENTS_TRANSACTION_EXPORT,
            paths.DOCUMENTS_CREATE_SOURCES_EXPORT,
            paths.DOCUMENTS_SOURCES_EXPORT,
            paths.TRADING_INSIGHT,
            paths.AML_CHEKS,
          ].includes(routePath),
          iWithoutMainPadding:
            isHere(paths.TRANSACTIONS)
            || location.pathname.includes(paths.TAX_REPORT_CREATE)
            || location.pathname.includes(paths.DOCUMENTS_CREATE_TRANSACTION_EXPORT)
            || location.pathname.includes(paths.DOCUMENTS_CREATE_SOURCES_EXPORT)
            || location.pathname.includes(paths.UNIFIED_TAX_REPORT_CREATE)
            || location.pathname.includes(paths.UNIFIED_TAX_REPORT_CREATE)
          // temporary code
          ,
          "light-grey": lightGreyMobile,
          // settings: location.pathname.includes(paths.SETTINGS),
          "dashboard-page": location.pathname === paths.HOME,
          "upload-operations-page": location.pathname === paths.DOCUMENTS_CREATE_TRANSACTION_EXPORT
            || location.pathname === paths.DOCUMENTS_CREATE_SOURCES_EXPORT ,
        })}
      >
        {children}
      </main>
      {isMaxMobile && <MobileTabMenu/>}
    </AntLayout>

    <AssetsModal/>
    <UpgradePlan/>
    <PricingActivateModal/>
    <AMLActivateModal/>
    <DashboardMobileOnboarding/>
  </Fragment>;
};

export default PrivateContainer;
