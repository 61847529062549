import { createSlice } from "@reduxjs/toolkit";

import { defaultState } from "../constants";
import { IPayloadAction } from "../rootInterface";

import {
  AmlRiskModel, AmlRiskModelInfoOptions, AmlRisksEntityCategoriesOptions,
  AmlRisksModelActivateOptions,
  AmlRisksModelCreateOptions,
  AmlRisksModelDeleteOptions, AmlRisksModelEditOptions, AmlRisksModelRulesOptions, AmlRisksModelUpdateRulesOptions,
  TAmlStoreState
} from "./types";

const initialState: TAmlStoreState = {
  amlSingleStateState: {},
  amlSingleCheckState: defaultState,
  amlSingleChecksState: {},
  amlStateState: defaultState,
  amlCheckAmllyState: defaultState,
  amlRiskSummaryState: defaultState,
  amlRisksModelsState: defaultState,
  amlRiskModelInfoState: defaultState,
  amlRisksModelCreateState: defaultState,
  amlRisksModelActivateState: defaultState,
  amlRisksModelDeleteState: defaultState,
  amlRisksEntityCategoriesState: defaultState,
  amlRisksModelEditState: defaultState,
  amlRisksModelRulesState: defaultState,
  amlRisksModelUpdateRulesState: defaultState,
  amlActivateDropdown: false
};

const amlSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    clearAmlState() {
      return initialState;
    },

    amlSingleStateRequest(state, action) {
      state.amlSingleStateState = {
        ...state.amlSingleStateState,
        [action.payload.id]: {
          ...state.amlSingleStateState[action.payload.id],
          fetching: true,
        },
      };
    },
    amlSingleStateSuccess(state, action) {
      state.amlSingleStateState = {
        ...state.amlSingleStateState,
        [action.payload.id]: {
          fetching: false,
          data: action?.payload.data,
          failure: null,
        },
      };
    },
    amlSingleStateFailure(state, action) {
      state.amlSingleStateState = {
        ...state.amlSingleStateState,
        [action.payload.id]: {
          fetching: false,
          data: null,
          failure: action?.payload.error,
        },
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlSingleCheckRequest(state, _) {
      state.amlSingleCheckState = {
        ...state.amlSingleCheckState,
        fetching: true,
      };
    },
    amlSingleCheckSuccess(state, action) {
      state.amlSingleCheckState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlSingleCheckFailure(state, action) {
      state.amlSingleCheckState = {
        ...state.amlSingleCheckState,
        fetching: false,
        failure: action.payload,
      };
    },

    amlSingleChecksRequest(state, action) {
      state.amlSingleChecksState = {
        ...state.amlSingleChecksState,
        [action.payload.id]: {
          ...state.amlSingleChecksState[action.payload.id],
          fetching: true,
        },
      };
    },
    amlSingleChecksSuccess(state, action) {
      state.amlSingleChecksState = {
        ...state.amlSingleChecksState,
        [action.payload.id]: {
          fetching: false,
          data: action.payload.data,
          prevData: state.amlSingleChecksState[action.payload.id]?.data || null,
          failure: null,
        },
      };
    },
    amlSingleChecksLoadMore(state, action) {
      const list = state.amlSingleChecksState[action.payload.id]?.data?.results || [];

      state.amlSingleChecksState = {
        ...state.amlSingleChecksState,
        [action.payload.id]: {
          ...action.payload.data,
          results: [
            ...list,
            ...action.payload.data.results
          ]
        },
      };
    },
    amlSingleChecksFailure(state, action) {
      state.amlSingleChecksState = {
        ...state.amlSingleChecksState,
        [action.payload.id]: {
          ...state.amlSingleChecksState[action.payload.id],
          fetching: false,
          failure: action.payload.error,
        },
      };
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlStateRequest(state, _) {
      state.amlStateState = {
        ...state.amlStateState,
        fetching: true,
      };
    },
    amlStateSuccess(state, action) {
      state.amlStateState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlStateFailure(state, action) {
      state.amlStateState = {
        ...state.amlStateState,
        fetching: false,
        failure: action.payload,
      };
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlCheckAmllyRequest(state, _) {
      state.amlCheckAmllyState = {
        ...state.amlCheckAmllyState,
        fetching: true,
      };
    },
    amlCheckAmllySuccess(state, action) {
      state.amlCheckAmllyState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlCheckAmllyFailure(state, action) {
      state.amlCheckAmllyState = {
        ...state.amlCheckAmllyState,
        fetching: false,
        failure: action.payload,
      };
    },

    amlRiskSummaryRequest(state) {
      state.amlRiskSummaryState = {
        ...state.amlRiskSummaryState,
        fetching: true,
      };
    },
    amlRiskSummarySuccess(state, action) {
      state.amlRiskSummaryState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRiskSummaryFailure(state, action) {
      state.amlRiskSummaryState = {
        ...state.amlRiskSummaryState,
        fetching: false,
        failure: action.payload,
      };
    },

    amlRisksModelsRequest(state) {
      state.amlRisksModelsState = {
        ...state.amlRisksModelsState,
        fetching: true,
      };
    },
    amlRisksModelsSuccess(state, action) {
      state.amlRisksModelsState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksModelsFailure(state, action) {
      state.amlRisksModelsState = {
        ...state.amlRisksModelsState,
        fetching: false,
        failure: action.payload,
      };
    },
    amlRisksModelsAppend(state, action: IPayloadAction<AmlRiskModel>) {
      state.amlRisksModelsState.data?.push(action.payload);
    },
    amlRisksModelRemove(state, action: IPayloadAction<{ id: string }>) {
      state.amlRisksModelsState.data =
        state.amlRisksModelsState.data?.filter(item => item.id !== action.payload.id) || [];
    },
    amlRisksModelEdit(state, action: IPayloadAction<AmlRiskModel>) {
      state.amlRisksModelsState.data = state.amlRisksModelsState.data?.map(item => {
        if (item.id !== action.payload.id) return item;
        return action.payload;
      }) || [];
    },
    amlRisksModelSetActivate(state, action: IPayloadAction<{ id: string }>) {
      state.amlRisksModelsState.data = state.amlRisksModelsState.data?.map(item => {
        if (item.id !== action.payload.id) return { ...item, is_active: false };
        return { ...item, is_active: true };
      }) || [];
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRiskModelInfoRequest(state, _: IPayloadAction<AmlRiskModelInfoOptions>) {
      state.amlRiskModelInfoState = {
        ...state.amlRiskModelInfoState,
        fetching: true,
      };
    },
    amlRiskModelInfoSuccess(state, action) {
      state.amlRiskModelInfoState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRiskModelInfoFailure(state, action) {
      state.amlRiskModelInfoState = {
        ...state.amlRiskModelInfoState,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRisksModelCreateRequest(state, _: IPayloadAction<AmlRisksModelCreateOptions>) {
      state.amlRisksModelCreateState = {
        ...state.amlRisksModelCreateState,
        fetching: true,
      };
    },
    amlRisksModelCreateSuccess(state, action) {
      state.amlRisksModelCreateState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksModelCreateFailure(state, action) {
      state.amlRisksModelCreateState = {
        ...state.amlRisksModelCreateState,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRisksModelActivateRequest(state, _: IPayloadAction<AmlRisksModelActivateOptions>) {
      state.amlRisksModelActivateState = {
        ...state.amlRisksModelActivateState,
        fetching: true,
      };
    },
    amlRisksModelActivateSuccess(state, action) {
      state.amlRisksModelActivateState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksModelActivateFailure(state, action) {
      state.amlRisksModelActivateState = {
        ...state.amlRisksModelActivateState,
        fetching: false,
        failure: action.payload,
      };
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRisksModelDeleteRequest(state, _: IPayloadAction<AmlRisksModelDeleteOptions>) {
      state.amlRisksModelDeleteState = {
        ...state.amlRisksModelDeleteState,
        fetching: true,
      };
    },
    amlRisksModelDeleteSuccess(state, action) {
      state.amlRisksModelDeleteState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksModelDeleteFailure(state, action) {
      state.amlRisksModelDeleteState = {
        ...state.amlRisksModelDeleteState,
        fetching: false,
        failure: action.payload,
      };
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRisksEntityCategoriesRequest(state, _: IPayloadAction<AmlRisksEntityCategoriesOptions>) {
      state.amlRisksEntityCategoriesState = {
        ...state.amlRisksEntityCategoriesState,
        fetching: true,
      };
    },
    amlRisksEntityCategoriesSuccess(state, action) {
      state.amlRisksEntityCategoriesState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksEntityCategoriesFailure(state, action) {
      state.amlRisksEntityCategoriesState = {
        ...state.amlRisksEntityCategoriesState,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRisksModelEditRequest(state, _: IPayloadAction<AmlRisksModelEditOptions>) {
      state.amlRisksModelEditState = {
        ...state.amlRisksModelEditState,
        fetching: true,
      };
    },
    amlRisksModelEditSuccess(state, action) {
      state.amlRisksModelEditState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksModelEditFailure(state, action) {
      state.amlRisksModelEditState = {
        ...state.amlRisksModelEditState,
        fetching: false,
        failure: action.payload,
      };
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRisksModelRulesRequest(state, _: IPayloadAction<AmlRisksModelRulesOptions>) {
      state.amlRisksModelRulesState = {
        ...state.amlRisksModelRulesState,
        fetching: true,
      };
    },
    amlRisksModelRulesSuccess(state, action) {
      state.amlRisksModelRulesState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksModelRulesFailure(state, action) {
      state.amlRisksModelRulesState = {
        ...state.amlRisksModelRulesState,
        fetching: false,
        failure: action.payload,
      };
    },


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    amlRisksModelUpdateRulesRequest(state, _: IPayloadAction<AmlRisksModelUpdateRulesOptions>) {
      state.amlRisksModelUpdateRulesState = {
        ...state.amlRisksModelUpdateRulesState,
        fetching: true,
      };
    },
    amlRisksModelUpdateRulesSuccess(state, action) {
      state.amlRisksModelUpdateRulesState = {
        fetching: false,
        data: action.payload,
        failure: null,
      };
    },
    amlRisksModelUpdateRulesFailure(state, action) {
      state.amlRisksModelUpdateRulesState = {
        ...state.amlRisksModelUpdateRulesState,
        fetching: false,
        failure: action.payload,
      };
    },

    setAmlActivateDropdown(state, action) {
      state.amlActivateDropdown = action.payload;
    }
  }
});

export const {
  clearAmlState,

  amlSingleStateRequest,
  amlSingleStateSuccess,
  amlSingleStateFailure,

  amlSingleCheckRequest,
  amlSingleCheckSuccess,
  amlSingleCheckFailure,

  amlSingleChecksRequest,
  amlSingleChecksLoadMore,
  amlSingleChecksSuccess,
  amlSingleChecksFailure,

  amlStateRequest,
  amlStateSuccess,
  amlStateFailure,

  amlCheckAmllyRequest,
  amlCheckAmllySuccess,
  amlCheckAmllyFailure,

  amlRiskSummaryRequest,
  amlRiskSummarySuccess,
  amlRiskSummaryFailure,

  amlRisksModelsRequest,
  amlRisksModelsSuccess,
  amlRisksModelsFailure,
  amlRisksModelsAppend,
  amlRisksModelRemove,
  amlRisksModelEdit,
  amlRisksModelSetActivate,

  amlRiskModelInfoRequest,
  amlRiskModelInfoSuccess,
  amlRiskModelInfoFailure,

  amlRisksModelCreateRequest,
  amlRisksModelCreateSuccess,
  amlRisksModelCreateFailure,

  amlRisksModelActivateRequest,
  amlRisksModelActivateSuccess,
  amlRisksModelActivateFailure,

  amlRisksModelDeleteRequest,
  amlRisksModelDeleteSuccess,
  amlRisksModelDeleteFailure,

  amlRisksEntityCategoriesRequest,
  amlRisksEntityCategoriesSuccess,
  amlRisksEntityCategoriesFailure,

  amlRisksModelEditRequest,
  amlRisksModelEditSuccess,
  amlRisksModelEditFailure,

  amlRisksModelRulesRequest,
  amlRisksModelRulesSuccess,
  amlRisksModelRulesFailure,

  amlRisksModelUpdateRulesRequest,
  amlRisksModelUpdateRulesSuccess,
  amlRisksModelUpdateRulesFailure,

  setAmlActivateDropdown
} = amlSlice.actions;
export default amlSlice.reducer;
