import React, { FC } from "react";

import SignInPage from "pages/auth/signIn";
import SignUpPage from "pages/auth/signup";
import ForgotPage from "pages/auth/forgot";
import EmailConfirmPage from "pages/auth/confirm-email";

import OAuthGetCodePage from "../pages/auth/oauth/get-code";
import OAuthCallbackPage from "../pages/auth/oauth/callback";

import { paths } from "./paths";

// Routes
export const HomePage = React.lazy(() => import("pages/home"));
export const DashboardPage = React.lazy(() => import("pages/dashboard"));
export const TradingInsightsPage = React.lazy(() => import("pages/tradingInsights"));
export const ProfilePage = React.lazy(() => import("pages/profile"));
export const AssetsPage = React.lazy(() => import("pages/assets"));
export const SingleAssetsPage = React.lazy(() => import("pages/assets/singleAsset"));
export const SettingsPage = React.lazy(() => import("pages/settings"));
export const SettingsSafetyPage = React.lazy(() => import("pages/settings/safety"));
export const SettingsServicesPage = React.lazy(() => import("pages/settings/services"));
export const SettingsOtherPage = React.lazy(() => import("pages/settings/other"));
export const SettingsPlanAndPayment = React.lazy(() => import("pages/settings/plan-and-payment"));
export const SettingsReportsPage = React.lazy(() => import("pages/settings/reports"));
export const SettingsAPIKeysPage = React.lazy(() => import("pages/settings/api-keys"));
export const TransactionsPage = React.lazy(() => import("pages/transactions"));
export const AMLChecks = React.lazy(() => import("pages/amlChecks"));
export const ReportPage = React.lazy(() => import("pages/documents"));
export const TaxReportCreatePage = React.lazy(() => import("pages/documents/create-tax-report"));
export const TaxReportSinglePage = React.lazy(() => import("pages/documents/single-tax-report"));
export const FormedReportPage = React.lazy(() => import("pages/documents/formed-tax-report"));
export const SingleReportOperationsPage = React.lazy(() => import("pages/documents/single-tax-report-operations"));
export const AMLChecksDetail = React.lazy(() => import("pages/amlChecksDetail"));

export const DocumentsCreateUnifiedTaxReport =
  React.lazy(() => import("pages/documents/create-unified-tax-report"));
export const DocumentsSingleUnifiedTaxReportPage =
  React.lazy(() => import("pages/documents/single-unified-tax-report"));
export const DocumentsCreateTransactionExportPage =
  React.lazy(() => import("pages/documents/create-transaction-export"));
export const DocumentsCreateSourcesExportPage =
  React.lazy(() => import("pages/documents/create-sources-export"));
export const DocumentsSingleTransactionExportPage =
  React.lazy(() => import("pages/documents/single-transaction-export"));
export const DocumentsFormedTransactionExportPage =
  React.lazy(() => import("pages/documents/formed-transaction-export"));
export const DocumentsSingleSourcesExportPage =
  React.lazy(() => import("pages/documents/single-sources-export"));
export const DocumentsFormedSourcesExportPage =
  React.lazy(() => import("pages/documents/formed-sources-export"));
export const PricingPage =
  React.lazy(() => import("pages/pricing"));
export const AMLPage =
  React.lazy(() => import("pages/aml"));
export const AMLSinglePage =
  React.lazy(() => import("pages/aml/singleAml"));
export const AMLSingleHistoryPage =
  React.lazy(() => import("pages/aml/singleAmlHistory"));

export const PublicAMLPage =
  React.lazy(() => import("pages/public/aml"));
export const PublicAccountPage =
  React.lazy(() => import("pages/public/account"));
export const PublicAccountTransactionsPage =
  React.lazy(() => import("pages/public/account-transactions"));

export type TRoutes = {
  path?: paths;
  component: FC;
  active: boolean;
  exact: boolean;
};

export const privateRoutes: TRoutes[] = [
  { path: paths.HOME, component: DashboardPage, active: true, exact: true },
  { path: paths.TRADING_INSIGHT, component: TradingInsightsPage, active: true, exact: true },
  { path: paths.PROFILE, component: ProfilePage, active: true, exact: true },
  { path: paths.TRANSACTIONS, component: TransactionsPage, active: true, exact: true },
  { path: paths.AML_CHEKS, component: AMLChecks, active: true, exact: true },
  { path: paths.ASSETS, component: AssetsPage, active: true, exact: true },
  { path: paths.SINGLE_ASSET, component: SingleAssetsPage, active: true, exact: true },
  { path: paths.SETTINGS, component: SettingsPage, active: true, exact: true },
  { path: paths.SETTINGS_SAFETY, component: SettingsSafetyPage, active: true, exact: true },
  {
    path: paths.SETTINGS_SERVICES,
    component: SettingsServicesPage,
    active: process.env.REACT_APP_SERVICES_DISABLED !== "true",
    exact: true
  },
  {
    path: paths.SETTINGS_PLAN_AND_PAYMENTS,
    component: SettingsPlanAndPayment,
    active: process.env.REACT_APP_TARIFFS_DISABLED !== "true",
    exact: true
  },
  { path: paths.SETTINGS_REPORTS, component: SettingsReportsPage, active: false, exact: true, },
  { path: paths.SETTINGS_OTHER, component: SettingsOtherPage, active: false, exact: true, },
  { path: paths.CONFIRM_EMAIL, component: EmailConfirmPage, active: true, exact: true, },
  {
    path: paths.UNIFIED_TAX_REPORT_CREATE,
    component: DocumentsCreateUnifiedTaxReport,
    active: true,
    exact: true,
  },
  {
    path: paths.UNIFIED_TAX_REPORT,
    component: DocumentsSingleUnifiedTaxReportPage,
    active: true,
    exact: true,
  },
  {
    path: paths.UNIFIED_TAX_REPORT_FORMED,
    component: DocumentsFormedTransactionExportPage,
    active: true,
    exact: true,
  },

  {
    path: paths.DOCUMENTS_CREATE_TRANSACTION_EXPORT,
    component: DocumentsCreateTransactionExportPage,
    active: true,
    exact: true,
  },
  {
    path: paths.DOCUMENTS_TRANSACTION_EXPORT,
    component: DocumentsSingleTransactionExportPage,
    active: true,
    exact: true,
  },
  {
    path: paths.DOCUMENTS_TRANSACTION_EXPORT_FORMED,
    component: DocumentsFormedTransactionExportPage,
    active: true,
    exact: true,
  },
  {
    // TODO AML_CHEKS => AML_CHECKS
    path: paths.AML_CHEKS_SINGLE,
    component: AMLChecksDetail,
    active: true,
    exact: true,
  },
  {
    path: paths.DOCUMENTS_CREATE_SOURCES_EXPORT,
    component: DocumentsCreateSourcesExportPage,
    active: true,
    exact: true,
  },
  {
    path: paths.DOCUMENTS_SOURCES_EXPORT,
    component: DocumentsSingleSourcesExportPage,
    active: true,
    exact: true,
  },
  {
    path: paths.DOCUMENTS_SOURCES_EXPORT_FORMED,
    component: DocumentsFormedSourcesExportPage,
    active: true,
    exact: true,
  },
  {
    path: paths.DOCUMENTS,
    component: ReportPage,
    active: process.env.REACT_APP_DOCUMENTS_DISABLED !== "true",
    exact: true,
  },
  { path: paths.TAX_REPORT_CREATE, component: TaxReportCreatePage, active: true, exact: true, },
  { path: paths.TAX_REPORT, component: TaxReportSinglePage, active: true, exact: true, },
  { path: paths.TAX_REPORT_FORMED, component: FormedReportPage, active: true, exact: true, },
  {
    path: paths.TAX_REPORT_OPERATIONS,
    component: SingleReportOperationsPage,
    active: true,
    exact: true,
  },
  {
    path: paths.PRICING,
    component: PricingPage,
    active: process.env.REACT_APP_TARIFFS_DISABLED !== "true",
    exact: true,
  },
  {
    path: paths.AML,
    component: AMLPage,
    active: process.env.REACT_APP_AML_DISABLED !== "true",
    exact: true,
  },
  {
    path: paths.KYT_API_KEYS,
    component: SettingsAPIKeysPage,
    active: true,
    exact: true,
  },
  {
    path: paths.SINGLE_AML,
    component: AMLSinglePage,
    active: process.env.REACT_APP_AML_DISABLED !== "true",
    exact: true,
  },
  {
    path: paths.SINGLE_AML_HISTORY,
    component: AMLSingleHistoryPage,
    active: process.env.REACT_APP_AML_DISABLED !== "true",
    exact: true,
  },
];

export const publicRoutes: TRoutes[] = [
  { path: paths.AUTH_OAUTH_GET_CODE, component: OAuthGetCodePage, active: true, exact: true, },
  { path: paths.AUTH_OAUTH_CALLBACK, component: OAuthCallbackPage, active: true, exact: true, },
  { path: paths.SIGN_IN, component: SignInPage, active: true, exact: true, },
  { path: paths.SIGN_UP, component: SignUpPage, active: true, exact: true, },
  { path: paths.FORGOT, component: ForgotPage, active: true, exact: true, },
  { path: paths.CONFIRM_EMAIL, component: EmailConfirmPage, active: true, exact: true, },
  {
    path: paths.PUBLIC_AML,
    component: PublicAMLPage,
    active: process.env.REACT_APP_AML_DISABLED !== "true",
    exact: true,
  },
  {
    path: paths.PUBLIC_ACCOUNT,
    component: PublicAccountPage,
    active: true,
    exact: true,
  },
  {
    path: paths.PUBLIC_ACCOUNT_TRANSACTIONS,
    component: PublicAccountTransactionsPage,
    active: true,
    exact: true,
  },
];

export const allRoutes = privateRoutes.concat(publicRoutes);