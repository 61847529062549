import { createSelector } from "@reduxjs/toolkit";

import { IApplicationState } from "../rootInterface";

const selectState = (state: IApplicationState) => state.amlChecks;

export const networksSelector = createSelector(selectState, catalog => catalog?.networks);
export const amlChecksSelector = createSelector(selectState, amlChecks => amlChecks.amlChecks);
export const explorerFindAddressOrTransactionSelector = createSelector(selectState,
  explorer => explorer.explorerFindAddressOrTransaction);
export const explorerTransactionSelector = createSelector(selectState,
  explorer => explorer.explorerFindAddressOrTransaction.data?.transaction);
export const amlChecksCheckTransferSelector =
  createSelector(selectState, amlChecks => amlChecks?.amlChecksCheckTransfer);
  
export const explorerAddressSelector = createSelector(selectState,
  explorer => explorer.explorerFindAddressOrTransaction.data?.address);
export const amlChecksCheckAddressSelector =
  createSelector(selectState, amlChecks => amlChecks?.amlChecksCheckAddress);
export const amlChecksDetailsSelector =
  createSelector(selectState, amlChecks => amlChecks?.amlChecksDetails);
export const amlChecksRisksSelector =
  createSelector(selectState, amlChecks => amlChecks?.amlChecksRisks);
export const amlChecksRecheckSelector =
  createSelector(selectState, amlChecks => amlChecks?.amlChecksRecheck);

export const amlCheckFilterMobile =
  createSelector(selectState, amlChecks => amlChecks?.amlFilterMobileGroups);
