import { EAmlSingleIds } from "./types";

export const formAmlSingleChecksId = (
  { account, archived, aml }: {account?: string, archived?: boolean, aml?: boolean}
): string|undefined => {
  if (archived) {
    return EAmlSingleIds.archived;
  } else if (aml) {
    return EAmlSingleIds.aml;
  }
  return account;
};