import { notification } from "antd";

import i18n from "../i18n";

export const showError = (text: string, description?: string): void => {
  notification.error({ message: text, description });
};

export const showSuccess = (text: string): void => {
  notification.success({ message: text });
};

export const showInfo = (text: string): void => {
  notification.info({ message: text });
};

export const clearNotification = (): void => {
  notification.destroy();
};

export const notificationNoChecksAvailable = (): void => showError(
  i18n.t("notification.noChecksAvailable"),
  i18n.t("notification.noChecksAvailableDescription")
);

